
var rd = {

	init: function() {

		// disable parent[0] menu click
		this.disableMenuClick();
	},

	disableMenuClick: function() {
		$("#primary-menu").find('.parent.has-submenu > a').click(function(e){
			e.preventDefault();
		});
	}

};

$(function(){

	// init default functions of this theme
	rd.init();

});